import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  Divider,
  Link,
  TextField,
  Paper,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import HeaderMain from "../components/headermain";
import { ConfigValue } from "../Config";
import { useUserAuth } from "../Hooks/user-use-auth";
import { auth, db } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useUserAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log("Google token response:", tokenResponse);

      const token = tokenResponse.access_token;
      console.log("Access token received from Google:", token);

      if (token) {
        try {
          const response = await axios.post(
            `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/api/login`,
            {
              token: token,
            }
          );

          if (response.status === 200) {
            const jsonResponse = response.data;
            login(jsonResponse);
            navigate("/dashboard");
          } else {
            alert(response.data.error || "Login failed");
          }
        } catch (error) {
          console.error("Login error:", error);
          alert("An error occurred during login. Please try again.");
        }
      } else {
        console.error("No token received from Google");
        alert("Failed to retrieve token from Google.");
      }
    },
    onError: (error) => {
      console.error("Google Login Error:", error);
      alert("Failed to login with Google. Please try again.");
    },
  });

  const handleAffiliateLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log("Logged in user:", user);

      const docRef = doc(db, "affiliates", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const affiliateData = docSnap.data();
        console.log("Affiliate data:", affiliateData);

        // Optionally, store affiliate data in context or state
        navigate("/affiliate-dashboard");
      } else {
        console.log("No such document in affiliates collection!");
        alert("No affiliate data found for this user.");
      }
    } catch (error) {
      console.error("Affiliate login error:", error);
      alert(error.message);
    }
  };

  return (
    <>
      {/* Header */}
      <HeaderMain />

      {/* Main content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "80vh",
          backgroundColor: "#FAFAFA",
          padding: "2rem",
        }}
      >
        {/* Welcome Message */}
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
          Welcome Back!
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
            Don’t have an account yet?{" "}
            <Link href="/adduser" color="primary">
              Sign up here.
            </Link>
          </Typography>

        {/* User Login Section */}
        <Paper
          elevation={3}
          sx={{
            padding: "2rem",
            marginBottom: "2rem",
            maxWidth: "400px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            User Login
          </Typography>
          <Divider sx={{ mb: 3 }} />
          {/* Google Login Button */}
          <Button
            variant="contained"
            startIcon={<GoogleIcon />}
            onClick={() => handleGoogleLogin()}
            sx={{
              width: "100%",
              mb: 3,
              textTransform: "none",
              borderRadius: "8px",
              backgroundColor: "#4285F4",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#357ae8",
              },
            }}
          >
            Continue with Google
          </Button>
        </Paper>

        {/* Affiliate Marketer Login Section */}
        <Paper
          elevation={3}
          sx={{
            padding: "2rem",
            maxWidth: "400px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            MyahAI Affiliate Login
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Box component="form" noValidate autoComplete="off">
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2, backgroundColor: "#215FFF" }}
              onClick={handleAffiliateLogin}
            >
              Login
            </Button>
          </Box>
        </Paper>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          mt: 4,
          backgroundColor: "#ffffff",
          p: 2,
          textAlign: "center",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © 2024 MyahAI. All rights reserved.
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/llama_logo.png"
            alt="Llama Logo"
            style={{ height: "30px", marginRight: "10px" }}
          />
          <Typography variant="body2" color="textSecondary">
            Built with Llama 3.1
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Login;