import React from "react";
import {
  Typography,
  Box,
  useTheme,
  Button,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import HeaderMain from "../components/headermain";

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <>
      {/* Header */}
      <HeaderMain /> {/* Use the new header component here */}

      {/* Main content section */}
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: isMobile ? "auto" : "80vh",
            padding: isMobile ? "20px" : "0",
          }}
        >
          {/* Text content */}
          <Box
            sx={{
              maxWidth: isMobile ? "100%" : "45%",
              textAlign: isMobile ? "center" : "left",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h1"
              style={{
                color: "#1D1E20",
                fontFamily: "Inter, sans-serif",
                fontSize: isMobile ? "2.5rem" : "4rem",
                letterSpacing: "-0.04em",
                lineHeight: "120%",
                marginBottom: "20px",
              }}
            >
              Meet Myah,<br />
              Your Accelerated<br />
              AI Assistant
            </Typography>
            <Typography
              variant="h5"
              style={{
                color: "#8C8C8C",
                fontFamily: "Inter, sans-serif",
                fontSize: isMobile ? "1.25rem" : "2rem",
                letterSpacing: "-0.04em",
                lineHeight: "129%",
                marginBottom: "30px",
                fontWeight: 300,
              }}
            >
              Enhance your productivity by automating mundane administrative tasks.
            </Typography>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#215FFF",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontSize: "20px",
                letterSpacing: "-0.04em",
                lineHeight: "129%",
                textTransform: "none",
                borderRadius: "20px",
                padding: "5px 20px",
                width: "fit-content",
                alignSelf: isMobile ? "center" : "flex-start",
              }}
              onClick={() => navigate("/adduser")}
            >
              Sign up now!
            </Button>
          </Box>

          {/* Image */}
          <Box
            sx={{
              maxWidth: isMobile ? "100%" : "50%",
              display: "flex",
              justifyContent: "center",
              marginTop: isMobile ? "30px" : "0",
              height: "auto",
            }}
          >
            <img
              src="/landing_img.png"
              alt="Phone displaying Myah"
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: "70vh",
                objectFit: "contain",
              }}
            />
          </Box>
        </Box>
      </Container>

      {/* Footer */}
      <Box
        sx={{
          mt: 4,
          backgroundColor: "#ffffff",
          p: 2,
          textAlign: "center",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © 2024 MyahAI. All rights reserved.
        </Typography>
        <Box
          sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <img src="/llama_logo.png" alt="Llama Logo" style={{ height: "30px", marginRight: "10px" }} />
          <Typography variant="body2" color="textSecondary">
            Built with Llama 3.1
          </Typography>
          <Link to="/privacypolicy" style={{ textDecoration: 'none', color: 'inherit' }}>
        Privacy Policy
      </Link>
        </Box>
      </Box>
    </>
  );
};

export default Home;
