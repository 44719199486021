import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Box, Typography, Paper, Container, Checkbox, FormControlLabel, Backdrop, CircularProgress } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGoogleLogin } from '@react-oauth/google';
import HeaderMain from '../components/headermain';
import { doc, updateDoc, query, where, getDocs, collection, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase';

const AddUser = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    company_name: '',
    address: '',
    type: 'normal',
    refercode: ''
  });
  const [step, setStep] = useState(1);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [waitGoogle, setWaitGoogle] = useState(false); // State for showing loading during Google login
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const scope = [
    'https://www.googleapis.com/auth/calendar',
    'https://www.googleapis.com/auth/calendar.events',
    'https://www.googleapis.com/auth/calendar.readonly',
    'https://mail.google.com/',
    'profile',
    'email',
  ];
  const scopeString = scope.join(' ');

  // Function to handle Google login, similar to what you used in AuthPage
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setWaitGoogle(true); // Show loading
        const { code } = tokenResponse;
        // Send the auth code to your backend to exchange for tokens
        const response = await axios.post(`https://myah-backend.vercel.app/users/update/${formData.phone_number}`, {
          google_code: code,
        });

        if (response.status === 200) {
          alert('Google Auth details updated successfully!');
          navigate(`/subscribe/${formData.phone_number}`); // Navigate to the next step
        } else {
          console.error("Failed to update Google Auth details:", response.data);
          alert("Failed to update Google Auth details.");
        }
      } catch (error) {
        console.error('Error during Google login:', error);
        alert('Error during Google login. Please try again.');
      } finally {
        setWaitGoogle(false); // Stop loading
      }
    },
    onError: (errorResponse) => {
      console.error('Google login error:', errorResponse);
      alert('Failed to login with Google. Please try again.');
    },
    scope: scopeString,
    flow: 'auth-code',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSubmit = {
        ...formData,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };
      await axios.post('https://myah-backend.vercel.app/users/add', dataToSubmit);

      if (formData.refercode) {
        const q = query(collection(db, 'affiliates'), where('code', '==', formData.refercode));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const affiliateDoc = querySnapshot.docs[0];
          const affiliateRef = doc(db, 'affiliates', affiliateDoc.id);

          await updateDoc(affiliateRef, {
            code: formData.refercode,
            onboardedUsers: arrayUnion({
              uid: formData.phone_number,
              first_name: formData.first_name,
              last_name: formData.last_name,
              email: formData.email,
              phone_number: formData.phone_number
            })
          });

          console.log('User added to affiliate onboardedUsers list');
        } else {
          console.log('No affiliate found with this referral code');
        }
      }

      alert('User added successfully!');
      googleLogin();
    } catch (error) {
      console.error('Error adding user:', error);
      alert('Failed to add user.');
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  return (
    <>
      <HeaderMain />
      {/* Loading backdrop */}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={waitGoogle}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Main Content */}
      <Container sx={{
        mt: 4,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        maxWidth: '100%',
        boxSizing: 'border-box'
      }}>
        {/* Left Side: Three Boxes */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? '100%' : '45%',
          mb: isMobile ? 2 : 0,
          maxWidth: '100%',
          boxSizing: 'border-box'
        }}>
          <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px', borderRadius: '20px', border: '1px solid #8C8C8C', flexGrow: 1 }}>
            <Typography variant="h5" sx={{
              fontFamily: 'Inter, sans-serif',
              fontWeight: 500,
              color: '#215FFF',
              border: '2px solid #215FFF',
              borderRadius: '10px',
              padding: '5px 10px',
              width: 'fit-content',
              textAlign: 'left',
            }}>
              Opt-in Workflow
            </Typography>
            <Typography variant="body1" sx={{
              color: '#8C8C8C',
              fontFamily: 'Inter, sans-serif',
              fontSize: isMobile ? '1rem' : '1.5rem',
              letterSpacing: '-0.04em',
              lineHeight: '129%',
              marginTop: '15px',
              fontWeight: 300,
            }}>
              To opt-in, you must be invited by a current Myah user via SMS. You must consent to SMS messaging for customer care and security reasons. Here’s more info on our <a href="/privacy">privacy & terms</a>.
            </Typography>
          </Paper>

          <Box sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            maxWidth: '100%'
          }}>
            <Paper
              elevation={3}
              sx={{
                padding: '20px',
                flex: 1,
                borderRadius: '20px',
                border: '1px solid #8C8C8C',
                backgroundColor: '#2E2E2E',
                marginRight: isMobile ? '0' : '20px',
                marginBottom: isMobile ? '20px' : '0',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#1D1E20',
                }
              }}
              onClick={() => navigate('/contact')}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ color: '#FFFFFF' }}>
                  Contact us
                </Typography>
              </Box>
            </Paper>

            <Paper elevation={3} sx={{ padding: '20px', flex: 1, borderRadius: '20px', border: '1px solid #8C8C8C' }}>
              <Typography variant="body2" sx={{
                color: '#8C8C8C',
                fontFamily: 'Inter, sans-serif',
                fontSize: isMobile ? '0.75rem' : '1rem',
                letterSpacing: '-0.04em',
                lineHeight: '129%',
                fontWeight: 300,
              }}>
                You will need to authorize access to a Google account through which the services are provided. This is required to manage your events and communicate with Myah.
              </Typography>
            </Paper>
          </Box>
        </Box>

        {/* Right Side: Create Account Form */}
        <Paper elevation={3} sx={{
          padding: '20px',
          width: isMobile ? '100%' : '45%',
          borderRadius: '20px',
          border: '1px solid #8C8C8C',
          flexGrow: 1,
          marginLeft: isMobile ? '0' : '40px',
          maxWidth: '100%',
          boxSizing: 'border-box'
        }}>
          {step === 1 && (
            <>
              <Typography variant="h1" sx={{
                color: '#1D1E20',
                fontFamily: 'Inter, sans-serif',
                fontSize: isMobile ? '1.5rem' : '2.5rem',
                letterSpacing: '-0.04em',
                lineHeight: '120%',
                marginBottom: '20px',
                mb: 2
              }}>
                Create Account
              </Typography>
              <form>
                <Box sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
                  <TextField
                    label="First Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    sx={{ mr: isMobile ? 0 : 1 }}
                  />
                  <TextField
                    label="Last Name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    sx={{ ml: isMobile ? 0 : 1 }}
                  />
                </Box>
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  type="email"
                  required
                />
                <TextField
                  label="Phone Number"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <FormControlLabel
                  control={<Checkbox checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} />}
                  label="I accept the terms and conditions"
                />
                <Box mt={2}>
                  <Button variant="contained" color="primary" fullWidth onClick={handleNextStep} disabled={!termsAccepted}>
                    Almost there <ArrowForward />
                  </Button>
                </Box>
              </form>
            </>
          )}
          {step === 2 && (
            <>
              <Typography variant="h1" sx={{
                color: '#1D1E20',
                fontFamily: 'Inter, sans-serif',
                fontSize: isMobile ? '1.5rem' : '2.5rem',
                letterSpacing: '-0.04em',
                lineHeight: '120%',
                marginBottom: '20px',
                mb: 2
              }}>
                Create Account
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Company Name"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Referral Code"
                  name="refercode"
                  value={formData.refercode}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <Box mt={2}>
                  <Button variant="contained" color="primary" type="submit" fullWidth disabled={!termsAccepted}>
                    Let's go <ArrowForward />
                  </Button>
                </Box>
              </form>
            </>
          )}
        </Paper>
      </Container>

      {/* Footer */}
      <Box sx={{ mt: 4, backgroundColor: "#ffffff", p: 2, textAlign: "center" }}>
        <Typography variant="body2" color="textSecondary">
          © 2024 MyahAI. All rights reserved.
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src="/llama_logo.png" alt="Llama Logo" style={{ height: '30px', marginRight: '10px' }} />
          <Typography variant="body2" color="textSecondary">
            Built with Llama 3.1
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default AddUser;