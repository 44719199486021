import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Drawer,
  Box,
  Typography,
  List,
  ListItem,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Logout as LogoutIcon,
  Dashboard as DashboardIcon,
  Contacts as ContactsIcon,
  Info as InfoIcon,
  SmartToy as MyahIcon,
  Login as GoogleIcon,
} from "@mui/icons-material";
import { drawerWidth } from "../Utils/constants.js";
import KeyboardArrowLeftSharp from "@mui/icons-material/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharp from "@mui/icons-material/KeyboardArrowRightSharp";
import routes from "../Config/routes.js";
import myahthumbnail from "../assets/myahthumbnail.jpg";

const listItems = [
  {
    to: routes.userDash,
    primary: "Dashboard",
    icon: <DashboardIcon />,
    tooltip: "View your dashboard",
  },
  {
    to: routes.googleAuth,
    primary: "Authenticate Google",
    icon: <GoogleIcon />,
    tooltip: "Authenticate your Google account",
  },
  {
    to: routes.contacts,
    primary: "Manage Contacts",
    icon: <ContactsIcon />,
    tooltip: "Manage your contact list",
  },
  {
    to: routes.editUserInfo,
    primary: "Edit User Info",
    icon: <InfoIcon />,
    tooltip: "Edit your personal information",
  },
  {
    to: routes.myahAI,
    primary: "Myah AI",
    icon: <MyahIcon />,
    tooltip: "Access Myah AI",
  },
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#f5f5f5", // Added background color
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // Increased width for minimized navbar
  width: `calc(${theme.spacing(9)} + 1px)`,
  backgroundColor: "#f5f5f5", // Added background color
});

const IconDrawer = styled(Drawer)(({ theme, extended }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(extended && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!extended && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    navigate("/login");
  };

  return (
    <IconDrawer variant="permanent" extended={isOpen}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 1,
        }}
      >
<Box
  component="img"
  src={myahthumbnail}
  alt="Myah Thumbnail"
  sx={{ width: 50, height: 50 }}
/>
        <IconButton onClick={handleToggle}>
          {isOpen ? <KeyboardArrowLeftSharp /> : <KeyboardArrowRightSharp />}
        </IconButton>
      </Box>
      <Divider />
      <List>
        {listItems.map((item) => (
          <Tooltip key={item.to} title={item.tooltip} placement="right">
            <ListItem
              button
              component={RouterLink}
              to={item.to}
              // Adjusted padding for list items
              sx={{ paddingY: 1 }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.primary}
                sx={{ opacity: isOpen ? 1 : 0 }}
              />
            </ListItem>
          </Tooltip>
        ))}
      </List>
      <Divider />
      <ListItem
        button
        onClick={handleLogout}
        // Adjusted padding for logout list item
        sx={{ paddingY: 1 }}
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" sx={{ opacity: isOpen ? 1 : 0 }} />
      </ListItem>
    </IconDrawer>
  );
};

export default Navbar;
